import React from "react";
import Main from "../components/main";
import Seo from "../components/section/seo";
import ESafetyComponent from "../components/esafety";

export default function ESafety() {
  return (
    <Main>
      <Seo title="VostroNet - eSafety" />
      <ESafetyComponent/>
    </Main>
  );
}
